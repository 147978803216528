







































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import Methods from '@/components/methods'
import { get, post } from '@/http'
import { alert } from '@/components/pop'

@Component
export default class Details extends Mixins(Methods) {
    details: any = {}
    imgList: any = []
    commentList: any = []
    praised: boolean = false
    type: number = 1
    comment: string = ''

    created(): void {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        this.getDetails()
    }
    getDetails(): void {
		get('software/detail', {
            userId: sessionStorage.getItem('userId'),
            softwareId: this.$route.params.id
        }).then((res: any) => {
            this.details = res.data.software
            this.imgList = res.data.softwareImgUrlList
            this.commentList = res.data.commentList
            this.praised = res.data.praised
            this.type = this.praised ? 2 : 1
        })
	}
    praise(): void {
        if (sessionStorage.getItem('userId') == null) {
            alert({text: '请登录后再点赞', state: 1})
            return
        }
        post('software/praise', {
            userId: sessionStorage.getItem('userId'),
            softwareId: this.$route.params.id,
            type: this.type
        }).then(() => {
            if (this.type === 1) {
                this.praised = true
                this.details.praiseCount = this.details.praiseCount + 1
                this.type = 2
            } else {
                this.praised = false
                this.details.praiseCount = this.details.praiseCount - 1
                this.type = 1
            } 
        })
    }
    submitCommewnt(): void {
        if (sessionStorage.getItem('userId') == null) {
            alert({text: '请登录后再评论', state: 1})
            return
        }
        if (!(this.comment.replace(/[, ]/g, '').length > 0)) {
			alert({text: '请输入您的想法', state: 1})
			return
		}
        post('software/comment/add', {
            userId: sessionStorage.getItem('userId'),
            softwareId: this.$route.params.id,
            content: this.comment
        }).then((res: any) => {
            this.comment = ''
            this.details.commentCount = this.details.commentCount + 1
            this.commentList = res.data
        })
    }
}
